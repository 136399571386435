<template>
  <b-card>

    <b-card-title>
      {{ $t('Segments') }}
    </b-card-title>

    <b-card-text>
      <b-row class="d-flex align-items-center justify-content-between">

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{  $t('Per page') }}</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50"/>
          </b-form-group>
        </b-col>

        <b-col md="6" class="my-1">
          <b-form-group label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" :placeholder="$t('Type to Search')"/>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  {{ $t('clear') }}  
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="table-container">

          <b-table  striped hover responsive :per-page="perPage"  :current-page="currentPage"  show-empty :busy="isLoading"  :items="segments" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

            <template #cell(id)="data">
              <b-badge class="font-weight-normal" variant="success">
                {{ data.item.id }}
              </b-badge>
            </template>

            <template #cell(arName)="data">
              <b-badge class="font-weight-normal" variant="success">
                {{ data.item.arName }}
              </b-badge>
            </template>

            <template #cell(enName)="data">
              <b-badge class="font-weight-normal" variant="success">
                {{ data.item.enName }}
              </b-badge>
            </template>

            <template #table-busy>
              <div class="text-center">
                <b-spinner  label="Loading..." />
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" class="my-0"/>
        </b-col>

      </b-row>
    </b-card-text>

  </b-card>

</template>

<script>
import {
  BModal,BSpinner, BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

import useJwt from '@/auth/useJwt'
import store from '@/store'

export default {
  components: {
    BTable,
    BModal,
    BAvatar,
    BBadge,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      segments: [

      ],
      isLoading:false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: this.$t('Id'), sortable: true },
        { key: 'enName', label: this.$t('EN Name'), sortable: true },
        { key: 'arName', label: this.$t('AR Name'), sortable: true }
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
    },
    isRtl() {
      return store.state.appConfig.layout.isRTL
    }
  },
  created() {
    this.getSegments();
  },
  mounted() {
    // Set the initial number of items

  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getSegments(){
      this.isLoading = true;
      useJwt.get('/asset/api/public/segments')
          .then(({data}) => {
            this.segments = data.data.segments;
            this.totalRows = this.segments.length
          })
          .catch(err => {
            console.error()
          })
          .finally(() => this.isLoading = false)
    },
    refreshFields() {
      this.fields = [
        { key: 'id', label: this.$t('Id'), sortable: true },
        { key: 'enName', label: this.$t('EN Name'), sortable: true },
        { key: 'arName', label: this.$t('AR Name'), sortable: true },
      ]
        
    }
  },
  watch: {
    isRtl: {
      deep: true,
      handler() {
          this.refreshFields()
      },

    }
  },
}
</script>
